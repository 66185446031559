import React from "react";

const BatteryIndicator = ({ batteryValue }) => {
  // Ensure batteryValue is within 0-100
  const level = Math.max(0, Math.min(100, batteryValue));

  // Determine battery fill color
  const getBatteryColor = () => {
    if (level > 60) return "#4caf50"; // Green for high battery
    if (level > 30) return "#ffc107"; // Yellow for medium battery
    return "#f44336"; // Red for low battery
  };

  // Calculate the height of the battery fill
  const fillHeight = (level / 100) * 80; // Adjust for SVG fill height (80 units)

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <svg
        width="30"
        height="25"
        viewBox="0 0 60 140"
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: "block" }}
      >
        {/* Battery Cap */}
        <rect x="22" y="5" width="25" height="10" rx="3" ry="3" fill="#333" />

        {/* Battery Body */}
        <rect
          x="5"
          y="15"
          width="60"
          height="100"
          rx="8"
          ry="8"
          fill="#ededed"
          stroke="#333"
          strokeWidth="2"
        />

        {/* Battery Fill */}
        <rect
          x="12"
          y={105 - fillHeight} // Fill grows upwards
          width="46"
          height={fillHeight}
          rx="6"
          ry="6"
          fill={getBatteryColor()}
          style={{
            transition: "height 0.3s ease-in-out",
          }}
        />

        {/* Gradient Definition */}
        <defs>
          <linearGradient id="battery-gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#f5f5f5" />
            <stop offset="100%" stopColor="#d0d0d0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
export default BatteryIndicator;
