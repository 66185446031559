import { InfoWindow, useMap } from "@vis.gl/react-google-maps";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Pin from "../../components/Mapbox/Pin";
import { Marker } from "./Marker";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
/**
 * The ClusteredDeviceMarkers component is responsible for integrating the
 * markers with the markerclusterer.
 */
export const ClusteredDeviceMarkers = ({ devices, setDevices }) => {
  const navigate = useNavigate()
  const [markers, setMarkers] = useState({});
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const selectedDevice = useMemo(
    () =>
      devices && selectedDeviceId
        ? devices.find((t) => {
            return t.device_id == selectedDeviceId;
          })
        : null,
    [devices, selectedDeviceId]
  );

  // create the markerClusterer once the map is available and update it when
  // the markers are changed
  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({ map });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;
    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
    // {zoomOnClick: false}
    // clusterer.
  }, [clusterer, markers]);

  // this callback will effectively get passsed as ref to the markers to keep
  // tracks of markers currently on the map
  const setMarkerRef = useCallback((marker, key) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedDeviceId(null);
  }, []);

  const handleMarkerClick = useCallback((device) => {
    navigate(`/devices/${device?.device_id}`) 
    setDevices(null)
  }, []);

  const handleMouseOver = useCallback((device) => {
    setSelectedDeviceId(device?.device_id);
  }, []);

  const handleMouseOut = useCallback(() => {
    setSelectedDeviceId(null);
  }, []);

  function formatTimestamp(timestamp) {
    const now = new Date();
    const date = new Date(timestamp * 1000);
    const diffInSeconds = Math.floor((now - date) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
    let relativeTime;
    if (diffInDays > 0) {
      relativeTime = rtf.format(-diffInDays, 'day');
    } else if (diffInHours > 0) {
      relativeTime = rtf.format(-diffInHours, 'hour');
    } else if (diffInMinutes > 0) {
      relativeTime = rtf.format(-diffInMinutes, 'minute');
    } else {
      relativeTime = rtf.format(-diffInSeconds, 'second');
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = String(date.getFullYear()).slice(-2); 
    const hours = date.getHours() % 12 || 12; 
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const period = date.getHours() >= 12 ? 'pm' : 'am'; 
    const exactTime = `${day} ${month} ${year}, ${hours}:${minutes}${period}`;
    return `${relativeTime} (${exactTime})`;
  }

  return (
    <>
      {devices.map((device) => (
        <Marker
          key={device.device_id}
          device={device}
          onClick={handleMarkerClick}
          onMouseOver={() => handleMouseOver(device)} // Pass hover event
          onMouseOut={handleMouseOut}
          setMarkerRef={setMarkerRef}
        >
          <Pin text="E" color="#ff5e00"></Pin>
        </Marker>
      ))}

      {selectedDeviceId && (
        <InfoWindow
          anchor={markers[selectedDeviceId]}
          onCloseClick={handleInfoWindowClose}
        >
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Device Id :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {selectedDevice?.device_id}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Status :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {selectedDevice?.is_active ? "Active" : "InActive"}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Last Seen :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {formatTimestamp(selectedDevice?.last_seen)}
            </Typography>
          </div>
          {/* <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Battery Level :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {selectedDevice?.battery_level}
            </Typography>
          </div> */}
        </InfoWindow>
      )}
    </>
  );
};
