import React from "react";

import { InfoWindow } from "@vis.gl/react-google-maps";
import { Typography } from "@mui/material";
import { calculateTotalTime, formatReadableDate } from "../../../utils/common";

const HoverDeviceDetails = ({ hoverInfo }) => {
  const hoverData = hoverInfo?.object?.properties;
  return (
    <>
      {hoverInfo && (
        <InfoWindow
          position={{
            lat: hoverInfo?.coordinate?.[1],
            lng: hoverInfo?.coordinate?.[0],
          }}
          anchor="left"
          pixelOffset={[0, -20]}
          disableAutoPan={true}
          shouldFocus={false}
          closeButton={false}
        >
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Device Id :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {hoverData?.device_id}
            </Typography>
          </div>
          {hoverData?.nature_of_work === "production" && (
            <div className="hoveredRow">
              <Typography component={"span"} variant="body2" fontWeight={600}>
                Equipment Name :{" "}
              </Typography>
              <Typography component={"span"} variant="body2">
                {hoverData?.beacon_name === "0.0"
                  ? "No Equipment"
                  : hoverData?.beacon_name}
                {hoverData?.beacon_name === "unknown" &&
                  `(${hoverData?.beacon_id})`}
              </Typography>
            </div>
          )}
          {/* {hoverData?.nature_of_work === 'production' &&<div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Beacon Type :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {hoverData?.beacon_type}
            </Typography>
          </div>} */}
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Date :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {hoverData?.date}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Start Time :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {formatReadableDate(hoverData?.start_time)}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              End Time :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {formatReadableDate(hoverData?.end_time)}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Total Time :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {calculateTotalTime(hoverData?.start_time, hoverData?.end_time)}
            </Typography>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default HoverDeviceDetails;
