import React, { useCallback } from "react";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { GiPositionMarker } from "react-icons/gi";

export const Marker = (props) => {
  const { device, onClick, setMarkerRef, onMouseOver, onMouseOut } = props;

  const handleClick = useCallback(() => onClick(device), [onClick, device]);
  const ref = useCallback(
    (marker) => setMarkerRef(marker, device?.device_id),
    [setMarkerRef, device.device_id]
  );

  return (
    <AdvancedMarker
      position={{
        lat: device?.last_gps?.coordinates[1],
        lng: device?.last_gps?.coordinates[0],
      }}
      ref={ref}
      onClick={handleClick}
    >
      <span
        className="clusterMarker"
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        {device?.is_active ? <GiPositionMarker color="green" size="26" /> : <GiPositionMarker color="grey" size="26"/>}
        
      </span>
    </AdvancedMarker>
  );
};
