import React, { useEffect, useRef, useState } from "react";
import Timeline from "react-visjs-timeline";
import "vis/dist/vis.min.css";
import {
  convertStartAndEndTimes,
  ganttChartDateTimeFormat,
} from "../../../utils/common";
import { useSelector } from "react-redux";
import { LayerColors } from "../../../utils/defaultVars";

const PropertyGanttChartDeviceWise = ({
  propertySummary,
  transitGanttData,
  missingGanttData,
  idleGanttData,
  isEquipmentWise,
}) => {
  const selectedDevicesDashboard = useSelector(
    (state) => state.gisDashboard.selectedDevicesDashboard
  );

  const beaconTypeColors = useSelector(
    (state) => state.gisDashboard.beaconTypeColors
  );
  const devicesEquipmentData = useSelector(
    (state) => state.gisDashboard.devicesEquipmentData
  );

  const timelineRef = useRef();
  const [groupData, setGroupData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [options, setOptions] = useState({
    stack: false,
    zoomMin: 1000,
    zoomMax: 100000000000,
    orientation: { axis: "top", item: "top" },
    groupOrder: "content",
    showTooltips: true,
    preferZoom: true,
    verticalScroll: true,
    groupHeightMode: "fitItems",
    groupOrder: function (a, b) {
      return b.value - a.value;
    },
  });

  const transformDataForTimeline = () => {
    const timelineData = [];
    Object.keys(selectedDevicesDashboard).forEach((deviceId) => {
      const filteredFeatures = propertySummary.features.filter((feature) => {
        const device_Id = feature?.properties?.device_id;

        // Check if the device exists in the dashboard and its beacon is true
        return deviceId === device_Id;
      });
      filteredFeatures?.forEach((feature) => {
        const { beacon_id, beacon_name, start_time, end_time, beacon_type } =
          feature?.properties || {};
        timelineData.push({
          id: `${beacon_id}-${deviceId}-${beacon_name}-${feature?.id}`,
          start: ganttChartDateTimeFormat(start_time),
          end: ganttChartDateTimeFormat(end_time),
          group: `${deviceId}-${beacon_name}-${beacon_id}`,
          title: `<div>
                <div class="individualContainer">
                <div>Device Name : ${deviceId}</div>
                <div>Equipment Name : ${beacon_name.replace("_", " ")}</div>
                <div>Start Time : ${ganttChartDateTimeFormat(start_time)}</div>
                <div>End Time : ${ganttChartDateTimeFormat(end_time)}</div>
                </div>
                </div>`,
          style: `background-color: ${
            isEquipmentWise
              ? beaconTypeColors[beacon_type]
              : LayerColors?.production?.hex
          }; border-color: ${
            isEquipmentWise
              ? beaconTypeColors[beacon_type]
              : LayerColors?.production?.hex
          }`,
        });
      });
      filteredFeatures?.forEach((feature) => {
        const { beacon_id, beacon_name, start_time, end_time, beacon_type } =
          feature?.properties || {};
        timelineData.push({
          id: `${beacon_id}-${deviceId}-${beacon_name}-${feature?.id}-${start_time}`,
          start: ganttChartDateTimeFormat(start_time),
          end: ganttChartDateTimeFormat(end_time),
          group: `${deviceId}`,
          title: `<div>
                <div class="individualContainer">
                <div>Device Name : ${deviceId}</div>
                <div>Equipment Name : ${
                  beacon_name === "0.0"
                    ? "No Equipment"
                    : beacon_name.replace("_", " ")
                }</div>
                <div>Start Time : ${ganttChartDateTimeFormat(start_time)}</div>
                <div>End Time : ${ganttChartDateTimeFormat(end_time)}</div>
                </div>
                </div>`,
          style: `background-color: ${
            isEquipmentWise
              ? beaconTypeColors[beacon_type]
              : LayerColors?.production?.hex
          }; border-color: ${
            isEquipmentWise
              ? beaconTypeColors[beacon_type]
              : LayerColors?.production?.hex
          }`,
        });
      });
    });

    idleGanttData?.features?.forEach((feature) => {
      const { beacon_id, beacon_name, start_time, end_time, device_id } =
        feature?.properties || {};
      timelineData.push({
        id: `${beacon_id}-${device_id}-${beacon_name}-${feature?.id}-${end_time}`,
        start: ganttChartDateTimeFormat(start_time),
        end: ganttChartDateTimeFormat(end_time),
        group: `${device_id}`,
        title: `<div>
            <div class="individualContainer">
            <div>Device Name : ${device_id}</div>
            <div>Equipment Name : ${
              beacon_name === "0.0"
                ? "No Equipment"
                : beacon_name.replace("_", " ")
            }</div>
            <div>Start Time : ${ganttChartDateTimeFormat(start_time)}</div>
            <div>End Time : ${ganttChartDateTimeFormat(end_time)}</div>
            </div>
            </div>`,
        className: "IdleNature",
      });
    });

    const uniqueTransitFeatures = [];
    const seenIds = new Set();

    transitGanttData?.features?.forEach((feature) => {
      if (!seenIds.has(feature.id)) {
        uniqueTransitFeatures.push(feature);
        seenIds.add(feature.id);
      }
    });
    // selectedDevicesTransitGeoJson?.features?.forEach((feature) => {
    //   if (!seenIds.has(feature.id)) {
    //     uniqueTransitFeatures.push(feature);
    //     seenIds.add(feature.id);
    //   }
    // });

    uniqueTransitFeatures?.forEach((feature) => {
      const { start_time, end_time, device_id, date } =
        feature?.properties || {};
      timelineData.push({
        id: `${device_id}-${feature?.id}-${end_time}-${start_time}`,
        start: ganttChartDateTimeFormat(start_time),
        end: ganttChartDateTimeFormat(end_time),
        group: `${device_id}`,
        title: `<div>
            <div class="individualContainer">
            <div>Device Name : ${device_id}</div>
            <div>Date : ${date}</div>
            <div>Start Time : ${ganttChartDateTimeFormat(start_time)}</div>
            <div>End Time : ${ganttChartDateTimeFormat(end_time)}</div>
            </div>
            </div>`,
        className: "TransitNature",
      });
    });
    missingGanttData?.features?.forEach((feature) => {
      const { start_time, end_time, device_id, beacon_name, date } =
        feature?.properties || {};
      timelineData.push({
        id: `${feature?.id}-${start_time}-${end_time}-${device_id}`,
        start: ganttChartDateTimeFormat(start_time),
        end: ganttChartDateTimeFormat(end_time),
        group: `${device_id}`,
        title: `<div>
            <div class="individualContainer">
            <div>Device Name : ${device_id}</div>
            <div>Date : ${date}</div>
            <div>Equipment Name : ${
              beacon_name === "0.0"
                ? "No Equipment"
                : beacon_name.replace("_", " ")
            }</div>
            <div>Start Time : ${ganttChartDateTimeFormat(start_time)}</div>
            <div>End Time : ${ganttChartDateTimeFormat(end_time)}</div>
            <div></div>
            </div>
            </div>`,
        className: "MissingNature",
      });
    });

    return timelineData;
  };

  const findMinMaxTimeFromLookup = () => {
    let minStartTime = null;
    let maxEndTime = null;

    const final = [
      ...(propertySummary?.features || []),
      ...(missingGanttData?.features || []),
      ...(transitGanttData?.features || []),
      ...(idleGanttData?.features || []),
    ];
    final?.forEach((feature) => {
      const { start_time, end_time } = feature?.properties || {};
      const startDate = ganttChartDateTimeFormat(start_time);
      const endDate = ganttChartDateTimeFormat(end_time);

      if (!minStartTime || startDate < minStartTime) {
        minStartTime = startDate;
      }
      if (!maxEndTime || endDate > maxEndTime) {
        maxEndTime = endDate;
      }
    });

    return { minStartTime, maxEndTime };
  };
  const transformGroupDataForTimeline = (devicesEquipmentData) => {
    const groupData = [];

    Object.keys(selectedDevicesDashboard).map((key) => {
      const nestedGroups = [];
      const equipmentData = devicesEquipmentData[key];
      if (key) {
        const group = {
          id: `${key}`,
          content: `${key}`,
          showNested: false,
        };

        equipmentData?.forEach((equipment) => {
          nestedGroups.push(
            `${key}-${equipment?.beacon_name}-${equipment?.beacon_id}`
          );
        });
        group.nestedGroups = nestedGroups;
        equipmentData?.forEach((equipment) => {
          groupData.push({
            id: `${key}-${equipment?.beacon_name}-${equipment?.beacon_id}`,
            content: `${
              equipment?.beacon_name === "0.0"
                ? "No Equipment"
                : equipment?.beacon_name
            }`,
          });
        });

        groupData.push(group);
      }
    });

    return groupData;
  };

  useEffect(() => {
    if (devicesEquipmentData && propertySummary) {
      const timelineData = transformDataForTimeline();
      setItemsData(timelineData);
      const groupData = transformGroupDataForTimeline(devicesEquipmentData);
      setGroupData(groupData);
      const bounds = findMinMaxTimeFromLookup();
      const chartStart = new Date(bounds.minStartTime);
      const chartEnd = new Date(bounds.maxEndTime);
      const adjustedTimes = convertStartAndEndTimes(chartStart, chartEnd);
      const clusterOpts = {
        start: adjustedTimes.start,
        end: adjustedTimes.end,
        // min: adjustedTimes.start + 1,
        // max: adjustedTimes.end + 1,
      };
      setOptions((prevOptions) => ({
        ...prevOptions,
        ...clusterOpts,
      }));
    }
  }, [
    devicesEquipmentData,
    propertySummary,
    transitGanttData,
    idleGanttData,
    missingGanttData,
    isEquipmentWise,
  ]);

  return (
    <div>
      {groupData && (
        <Timeline
          ref={timelineRef}
          options={options}
          groups={groupData}
          items={itemsData}
        />
      )}
    </div>
  );
};

export default PropertyGanttChartDeviceWise;
