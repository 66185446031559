import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { StyledTableCell } from "../MapScreen/PlannerDetailsDrawer";
import BatteryIndicator from "../../components/BatteryIndicator/BatteryIndicator";
import { MdBolt } from "react-icons/md";

const DevicesDetailsPopup = ({ devices }) => {


   function formatTimestamp(timestamp) {
    const now = new Date();
    const date = new Date(timestamp * 1000);
  
    const diffInSeconds = Math.floor((now - date) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
    let relativeTime;
    if (diffInDays > 0) {
      relativeTime = rtf.format(-diffInDays, 'day');
    } else if (diffInHours > 0) {
      relativeTime = rtf.format(-diffInHours, 'hour');
    } else if (diffInMinutes > 0) {
      relativeTime = rtf.format(-diffInMinutes, 'minute');
    } else {
      relativeTime = rtf.format(-diffInSeconds, 'second');
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = String(date.getFullYear()).slice(-2);
    const hours = date.getHours() % 12 || 12; 
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const period = date.getHours() >= 12 ? 'pm' : 'am'; 
    const exactTime = `${day} ${month} ${year}, ${hours}:${minutes}${period}`;
    return `${relativeTime} (${exactTime})`;
  }
  



  return (
    <Box className="devicesPopupContainer">
      <Box className="devicesPopupHeader">
        <Typography fontWeight={'bold'} variant="body1">Vests</Typography>
        <Box className="devicesPopupHeaderRight">
          <Box className="activeDevice" />
          <Typography variant="body2">Active</Typography>
          <Box className="inActiveDevice" />
          <Typography variant="body2">Inactive</Typography>
        </Box>
      </Box>
      <TableContainer
        className="vestTable"
        sx={{ height: 300 }}
        component={Paper}
      >
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Vest ID</StyledTableCell>
              <StyledTableCell align="center">Battery</StyledTableCell>
              <StyledTableCell align="left">Last Seen</StyledTableCell>
              {/* <StyledTableCell align="center">Time Spent</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {devices &&
              devices?.map((stat, index) => {
                const {
                  device,
                  batteryStatus,
                  lastSeen,
                  isActive,
                  batteryValue,
                } = stat;
                return (
                  <TableRow key={index}>
                    <StyledTableCell style={{ fontSize: "13px" }} align="left">
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          className={
                            isActive ? "activeDevice" : "inActiveDevice"
                          }
                        />
                        {device}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ fontSize: "13px", display: "flex", alignItems: "center" }}
                      align="center"
                    >
                      <BatteryIndicator batteryValue={batteryValue} />
                        {batteryValue}% {batteryStatus === "Charging" && <MdBolt size={16} color="#dba102" />}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ fontSize: "13px" }}
                      align="center"
                    >
                      {formatTimestamp(lastSeen)}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DevicesDetailsPopup;
