import toGeoJSON from "@mapbox/togeojson";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useUploadKmlMutation } from "../../services/Properties";
import { useDispatch } from "react-redux";
import { setUploadedKmlData } from "../../slices/gisDashboardSlice";
import { toast } from "react-toastify";
import { getCustomColor } from "../../utils/theme";

const UploadKmlModal = ({ isOpen, setIsOpen, locationGroups }) => {
  const blackShade1 = getCustomColor("blackShade1");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm();
  const [uploadKml, { isLoading }] = useUploadKmlMutation();
  const [file, setFile] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [isOffice, setIsOffice] = useState(false);
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const handleDragOver = (event) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const uploadedFile = event.dataTransfer.files[0];
      setFile(uploadedFile);
      setValue("file", uploadedFile);
      clearErrors("file");
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const uploadedFile = event.target.files[0];
      setFile(uploadedFile);
      setValue("file", uploadedFile);
      clearErrors("file");
    }
  };

  const extractComponentName = (geoJsonData) => {
    if (
      geoJsonData &&
      geoJsonData.features &&
      geoJsonData.features.length > 0
    ) {
      const firstFeature = geoJsonData.features[0];
      if (firstFeature.properties && firstFeature.properties.componentName) {
        // Get componentName, lowercase it, remove spaces, and prepend "landscape-"
        return `landscape-${firstFeature.properties.componentName
          .toLowerCase()
          .replace(/\s+/g, "")}`;
      } else {
        return `landscape-parcel`;
      }
    }
    return "others";
  };
  const extractDescription = (geoJsonData, location) => {
    if (
      geoJsonData &&
      geoJsonData.features &&
      geoJsonData.features.length > 0
    ) {
      const firstFeature = geoJsonData.features[0];
      if (firstFeature.properties && firstFeature.properties.componentName) {
        // Get componentName, lowercase it, remove spaces, and prepend "landscape-"
        return `${location}-${firstFeature.properties.componentName
          .toLowerCase()
          .replace(/\s+/g, "")}`;
      } else {
        return `${location}-landscape-parcel`;
      }
    }
    return "others";
  };

  const combineFeaturesIntoSingleGeometry = (geoJsonData) => {
    // const crs = {
    //   type: 'name',
    //   properties: {
    //     name: 'EPSG:4326',
    //   },
    // };

    if (geoJsonData.type === "FeatureCollection") {
      const geometryTypes = geoJsonData.features.map(
        (feature) => feature.geometry.type
      );
      const uniqueGeometryTypes = [...new Set(geometryTypes)];

      let combinedGeometry;

      if (uniqueGeometryTypes.length === 1) {
        const geometryType = uniqueGeometryTypes[0];

        if (geometryType === "LineString") {
          combinedGeometry = {
            type: "MultiLineString",
            coordinates: geoJsonData.features.map(
              (feature) => feature.geometry.coordinates
            ),
            // crs: crs,
          };
        } else if (geometryType === "Polygon") {
          combinedGeometry = {
            type: "MultiPolygon",
            coordinates: geoJsonData.features.map((feature) => {
              return feature.geometry.coordinates.map((ring) => {
                if (ring[0] !== ring[ring.length - 1]) {
                  ring.push(ring[0]); // Close the ring
                }
                return ring.filter((pos) => pos.length >= 2);
              });
            }),
            // crs: crs,
          };
        } else {
          combinedGeometry = {
            type: geometryType,
            coordinates: geoJsonData.features.map(
              (feature) => feature.geometry.coordinates
            ),
            // crs: crs,
          };
        }
      } else {
        combinedGeometry = {
          type: "GeometryCollection",
          geometries: geoJsonData.features.map((feature) => feature.geometry),
          // crs: crs,
        };
      }

      return combinedGeometry;
    } else {
      return geoJsonData.geometry || geoJsonData;
    }
  };

  const onSubmit = (data) => {
    const file = data?.file;
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          const kmlData = reader.result;
          const parser = new DOMParser();
          const kml = parser.parseFromString(kmlData, "text/xml");
          const geoJsonData = toGeoJSON.kml(kml);
          const combinedGeometry =
            combineFeaturesIntoSingleGeometry(geoJsonData);
          const componentName = extractComponentName(geoJsonData);
          const convertedDescription = extractDescription(
            geoJsonData,
            data?.selectedOption?.key
          );

          if (combinedGeometry) {
            const payload = {
              key: convertedDescription,
              description: convertedDescription,
              group: data?.selectedOption?.key,
              geom: combinedGeometry,
              is_office: isOffice,
              location_type: componentName, // landscape-hard-edge, landscape-turf, landscape-soft-edge
              zone: "string",
              map_grid: "string",
              street: "string",
              suburb: "string",
              zipcode: "string",
              state: "string",
              address: "string",
              country: "US",
              is_global: true,
              alias: "string",
              coverage_threshold: 100,
            };
            const response = await uploadKml({
              orgName: user?.organization,
              content: payload,
            });
            if (response?.data) {
              dispatch(setUploadedKmlData(response?.data));
              handleCancel();
              toast.success("KML uploaded successfully", {
                autoClose: 2000,
                hideProgressBar: true,
              });
            } else {
              toast.error("Some error occured", {
                autoClose: 2000,
                hideProgressBar: true,
              });
            }
          }
        } catch (err) {
          toast.error("Some error occured", {
            autoClose: 2000,
            hideProgressBar: true,
          });
          //   setError('Error processing or posting data: ' + err.message);
        }
      };
      reader.readAsText(file);
    }
  };
  const handleCancel = () => {
    setIsOpen(false);
    reset();
    setFile(null);
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitle fontWeight={700} fontSize="24px" align="left">
        Upload file
      </DialogTitle>
      <Divider />
      <div className="dialogContainer">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: 400,
            mx: "auto",
            borderRadius: 2,
          }}
        >
          {/* Select Field */}
          <FormControl fullWidth error={!!errors.selectedOption} sx={{ mb: 2 }}>
            <InputLabel size="small">Select an Option</InputLabel>
            <Select
              size="small"
              label="Select an Option"
              {...register("selectedOption", {
                required: "Please select an option",
              })}
            >
              {locationGroups &&
                locationGroups.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location.name}
                  </MenuItem>
                ))}
            </Select>
            {errors.selectedOption && (
              <FormHelperText>{errors.selectedOption.message}</FormHelperText>
            )}
          </FormControl>

          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Typography
              variant="body2"
              fontWeight="600"
              sx={{
                color: blackShade1.main,
                fontSize: "16px",
                marginBottom: "10px"
              }}
              component={"span"}
            >
              Is Office Parcel ?
            </Typography>
            <Switch
              size="small"
              checked={isOffice}
              onChange={() => setIsOffice(!isOffice)}
            />
          </Box>

          {/* Drag and Drop File Upload */}
          <Box
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              border: "2px dashed",
              borderColor: dragActive ? "primary.main" : "grey.400",
              p: 4,
              textAlign: "center",
              mb: 2,
              borderRadius: 2,
              backgroundColor: dragActive ? "grey.100" : "transparent",
            }}
          >
            <Typography>
              {file ? file.name : "Drag & Drop a file or click to upload"}
            </Typography>
            <input
              type="file"
              accept=".kml"
              style={{ display: "none" }}
              onChange={handleFileChange}
              id="fileUpload"
            />
            <label htmlFor="fileUpload" style={{ cursor: "pointer" }}>
              <Typography
                sx={{ color: "primary.main", textDecoration: "underline" }}
              >
                Browse Files
              </Typography>
            </label>
          </Box>
          {errors.file && (
            <Typography color="error">{errors.file.message}</Typography>
          )}
          <input
            {...register("file", { required: "Please upload a file" })}
            type="hidden"
          />
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button disabled={isLoading} variant="contained" type="submit">
              {isLoading ? <CircularProgress size={24} /> : "Upload"}
            </Button>
          </Box>
        </Box>
      </div>
    </Dialog>
  );
};

export default UploadKmlModal;
